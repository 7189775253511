<div class="dialog position-relative">
  <div class="close-button" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="text-center">
      <p class="message-one">{{ data.messageOne }}</p>
      <p class="message-two">{{ data.messageTwo }}</p>
    </div>
    <mat-dialog-actions class="mt-4" align="center">
      <tes-button
        class="reject-button"
        *ngIf="data.rejectButton"
        [title]="data.rejectButtonTitle"
        (click)="closeDialog()"></tes-button>

      <tes-button
        [title]="title$ | async"
        [disabled]="disabled$ | async"
        (click)="submitDialog()"></tes-button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
