import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routingPathEnum } from './shared/models';

const routes: Routes = [
  {
    path: '',
    redirectTo: routingPathEnum.Client,
    pathMatch: 'full',
  },
  {
    path: 'client',
    loadChildren: () => import('./modules/client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'auth-broker',
    loadChildren: () => import('./modules/dealer/dealer.module').then((m) => m.DealerModule),
  },
  {
    path: 'info',
    loadComponent: () => import('./modules/info/info.component').then((m) => m.InfoComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
